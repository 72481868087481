import { create } from 'zustand';
import { Frame } from '~/lib/data-contract';

export type UseFrameState = {
    setFrameStateData: (frame: Frame) => void;
    clearFrameStateData: () => void;
    frameStateData: Frame | undefined;
};

export const useFrameState = create<UseFrameState>((set) => ({
    setFrameStateData: (frame: Frame) => set({ frameStateData: frame }),
    clearFrameStateData: () => set({ frameStateData: undefined }),
    frameStateData: undefined,
}));
