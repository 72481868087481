import React, { forwardRef, memo, useEffect, useRef, useState } from 'react';
import {
    Wrapper,
    StyledActions,
    StyledBackdrop,
    StyledCloseButton,
    StyledContent,
    StyledFooter,
    StyledHeader,
    StyledInner,
} from './styled';
import Close from '$icons/close-bold.svg';
import { Headline } from '~/templates/blocks/components/Headline/Headline';
import { Icon, Portal, ScrollArea } from '$shared/components';
import { useScrollLock } from '$shared/hooks';
import { getLocaleDirection, useTranslation } from '~/shared/utils/translation';

interface Props {
    children?: React.ReactNode;
    direction?: 'left' | 'right';
    footerContent?: React.ReactNode | React.ReactNode[];
    open: boolean;
    onClose: () => void;
    title?: string;
    maxWidth?: number;
    withSSR?: boolean;
    sidePadding?: 'none' | 'default' | 'large';
    hideHeader?: boolean;
    transparentBackdrop?: boolean;
    culture?: string;
}

interface ContentProps extends Props {
    active: boolean;
    isSSR: boolean;
    setActive: (active: boolean) => void;
}

const ContentMemo = memo(
    forwardRef<HTMLDivElement, ContentProps>(
        (
            {
                open,
                active,
                onClose,
                setActive,
                children,
                direction = 'left',
                title,
                footerContent,
                maxWidth = 675,
                isSSR,
                sidePadding = 'default',
                hideHeader = false,
                transparentBackdrop = false,
            },
            ref,
        ) => {
            const { translate } = useTranslation();
            return (
                <Wrapper
                    open={open && active}
                    direction={direction}
                    ref={ref}
                    tabIndex={0}
                    isSSR={isSSR}
                    role="dialog"
                >
                    <StyledBackdrop
                        onClick={onClose}
                        onTransitionEnd={() => setActive(open)}
                        transparentBackdrop={transparentBackdrop}
                    />
                    <StyledContent
                        className="sidepanel-content"
                        maxWidth={maxWidth}
                        withBoxShadow={transparentBackdrop}
                    >
                        {!hideHeader && (
                            <StyledHeader style={title ? {} : { paddingBottom: 0 }}>
                                <StyledActions>
                                    <StyledCloseButton
                                        onClick={onClose}
                                        aria-label={translate('Kompan.Generic.Close')}
                                    >
                                        <Icon size="sm">
                                            <Close />
                                        </Icon>
                                    </StyledCloseButton>
                                </StyledActions>
                                {title && <Headline variant="display3">{title}</Headline>}
                            </StyledHeader>
                        )}

                        <ScrollArea>
                            <StyledInner sidePadding={sidePadding}>{children}</StyledInner>
                        </ScrollArea>
                        {footerContent && (
                            <StyledFooter sidePadding={sidePadding}>{footerContent}</StyledFooter>
                        )}
                    </StyledContent>
                </Wrapper>
            );
        },
    ),
);

const getLocaleDrawerDirection = (culture: string) => {
    return getLocaleDirection(culture) === 'rtl' ? 'right' : 'left';
};

export function Sidepanel({
    open,
    onClose,
    children,
    direction,
    title,
    footerContent,
    maxWidth = 675,
    withSSR = false,
    sidePadding = 'default',
    hideHeader = false,
    transparentBackdrop = false,
    culture = 'en',
}: Props) {
    const { lock, unlock } = useScrollLock();

    const [active, setActive] = useState(false);
    const [showSSR, setShowSSR] = useState(true);
    const contentRef = useRef<HTMLDivElement | null>(null);
    const [triggerElement, setTriggerElement] = useState<HTMLElement | null>(null);

    const directionToRender = direction ?? getLocaleDrawerDirection(culture);

    useEffect(() => {
        /**
         * This will be set as soon as we're in client.
         * By doing this we can render the content directly in the server response.
         * The content will never be shown to the user.
         */
        setShowSSR(false);
    }, []);

    useEffect(() => {
        const keyHandler = (e: KeyboardEvent) => [27].indexOf(e.which) >= 0 && onClose && onClose();
        open && window.addEventListener('keyup', keyHandler);
        if (open) {
            window.setTimeout(() => {
                const { activeElement } = document;
                if (activeElement instanceof HTMLElement) {
                    setTriggerElement(activeElement);
                    activeElement.blur();
                }
                setActive(open);
                lock();
                // Set focus to SidePanel content
                if (contentRef.current) {
                    contentRef.current.focus();
                }
            }, 10);
        } else {
            unlock();
            // Set focus back to whatever opened the SidePanel in the first place
            if (triggerElement) {
                triggerElement.focus();
            }
        }

        return () => {
            window.removeEventListener('keyup', keyHandler);
        };
    }, [open]);

    if (withSSR && showSSR)
        return (
            <ContentMemo
                isSSR={true}
                ref={contentRef}
                setActive={setActive}
                active={active}
                onClose={onClose}
                direction={directionToRender}
                title={title}
                footerContent={footerContent}
                maxWidth={maxWidth}
                open={open || active}
                children={children}
                sidePadding={sidePadding}
                hideHeader={hideHeader}
                transparentBackdrop={transparentBackdrop}
            />
        );
    return (
        <Portal>
            {(open || active) && (
                <ContentMemo
                    isSSR={false}
                    ref={contentRef}
                    setActive={setActive}
                    active={active}
                    onClose={onClose}
                    direction={directionToRender}
                    title={title}
                    footerContent={footerContent}
                    maxWidth={maxWidth}
                    open={open && active}
                    children={children}
                    sidePadding={sidePadding}
                    hideHeader={hideHeader}
                    transparentBackdrop={transparentBackdrop}
                />
            )}
        </Portal>
    );
}
