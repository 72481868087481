import { useContext, useEffect, useMemo, useState } from 'react';
import { useGetRequest } from '~/shared/utils';
import { FrameContext } from '../context/FrameProvider';
import { Frame } from '..';
import { useMarket } from '~/shared/utils/market';
import { useRouter } from 'next/router';
import { useFrameState } from '$shared/utils/frame/hooks/useFrameState';
import { getPathname } from '../../helpers/url.helper';
import { QueryParams } from '../../request/utils/buildURL';

const useFrameContext = () => {
    return useContext(FrameContext);
};

/**
 * Frame hook (top menu, footer, settings, and other things that are not page-specific).
 * Will load initial data provided by context on initial load, and fetch new values when culture or market changes
 * Always try to provide a value, even if it's from the previously loaded frame, but the isLoading will indicate
 * if it's serving stale data
 */
export const useFrame = () => {
    const { initialData, isMyKompan = false } = useFrameContext();
    const [previousFrame, setPreviousFrame] = useState<Frame | null>(initialData || null);
    const { frameStateData, setFrameStateData } = useFrameState();
    const { culture, market } = useMarket();
    const router = useRouter();

    const routerUrl = router.asPath;

    const url = useMemo(() => {
        const pathname = getPathname(routerUrl);
        return '/' + pathname.split('/').slice(1, 3).join('/');
    }, [routerUrl]);

    const endpointUrl = `/api/proxy/content/frame`;
    const params: QueryParams = {
        url: url,
    };

    if (routerUrl.includes('preview=true')) {
        params.preview = 'true';
    }
    if (isMyKompan) {
        params.isMyKompan = 'true';
    }

    const { data, isLoading, invalidate } = useGetRequest<Frame>(`${endpointUrl}`, {
        params: params,
        initialData,
        enabled: true,
    });

    useEffect(() => {
        if (
            data &&
            frameStateData &&
            frameStateData.culture !== data.culture &&
            frameStateData.market?.market !== data.market?.market
        ) {
            setFrameStateData(data);
        }
    }, [data]);

    useEffect(() => {
        const { culture: currentCulture } = data || {};

        if (currentCulture !== culture) {
            invalidate();
        }
    }, [culture, market]); // Invalidate on culture/market change

    useEffect(() => {
        if (data && previousFrame !== data) {
            setPreviousFrame(data);
        }
    }, [data]);

    return { data: data ?? (previousFrame as Frame) ?? initialData, isLoading, isMyKompan };
};
