import { useAuth, usePreviewModeState } from '$features/authentication';
import { StorageKey } from '$shared/hooks';
import { getCookie } from 'cookies-next';

export const useFetcher = () => {
    const { refreshToken } = useAuth();
    const { isPreviewUserAuthenticated } = usePreviewModeState();

    const fetcher = <T>(
        url: string,
        withAuth: boolean,
        loginPageUrl: string,
        loginRedirectPageUrl: string,
        options?: ResponseInit
    ) => async () => {
        if (withAuth) {
            await refreshToken(loginPageUrl, loginRedirectPageUrl);
        }

        const response = await fetch(
            url,
            !withAuth
                ? options
                : {
                      ...options,
                      headers: !isPreviewUserAuthenticated
                          ? {
                                ...options?.headers,
                                Authorization: `Bearer ${getCookie(StorageKey.authToken)}`,
                            }
                          : {
                                ...options?.headers,
                            },
                  }
        );
        const contentType = response.headers.get('content-type') || '';

        if (!response.ok) {
            throw new Error(`${response.status} ${response.statusText} at ${url}`);

            // Currently only support JSON
        } else if (!contentType.includes('application/json')) {
            throw Error(`Content type ${contentType} handling not implemented`);
        }

        const data: T = await response.json();
        return data;
    };

    return {
        fetcher,
    };
};
